import { request } from 'shared/auth';
import { trackClick } from 'shared/util/tracking/mercury';
import { posthogCapture } from 'shared/vendor/posthog';
import { Button } from 'wiw/ui';

import googleIcon from 'shared/assets/google-icon.svg';

type GoogleSigninButtonProps = {
  text?: string,
}

const GoogleSigninButton = ({ text }: GoogleSigninButtonProps) => {

  function click() {
    posthogCapture('Sign up form submitted', {
      'Sign up details submitted': ['None'],
      'Signed up with SSO': true,
      'SSO type': 'Google',
    });

    request().get('/sso/google/url').then(({ url }) => {
      window.location = url;
    });
  }

  return (
    <Button
      onClick={ () => { trackClick(text || 'Log in with Google'); click(); } }
      color="secondary"
      className="btn-normalcase btn-md"
      style={ { color: '#666' } }
    >
      <img className="btn-img" src={ googleIcon } role="presentation" />
      <span className="btn-contents">{ text || 'Log in with Google' }</span>
    </Button>
  );
};

export default GoogleSigninButton;
