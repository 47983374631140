import { Component } from 'react';
import PropTypes from 'prop-types';

import { trackClick } from 'shared/util/tracking/mercury';
import { posthogCapture } from 'shared/vendor/posthog';
import { Button } from 'wiw/ui';

import jwtDecode from 'jwt-decode';
import queryString from 'query-string';

import appleIcon from '../assets/apple-icon.svg';

const LOCALSTORAGE_APPLE_USERS_KEY = 'appleUsersById';

function getAppleUsers() {
  const usersJSON = localStorage.getItem(LOCALSTORAGE_APPLE_USERS_KEY);
  if (usersJSON) {
    return JSON.parse(usersJSON);
  } else {
    return {};
  }
}

function saveAppleUser(userId, userObject) {
  const users = getAppleUsers();
  users[userId] = userObject;
  localStorage.setItem(LOCALSTORAGE_APPLE_USERS_KEY, JSON.stringify(users));
}

export class AppleSigninButton extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    text: PropTypes.string,
  };

  click = () => {
    AppleID.auth.signIn()
      .then(detail => {
        posthogCapture('Sign up form submitted', {
          'Sign up details submitted': ['None'],
          'Signed up with SSO': true,
          'SSO type': 'Apple',
        });

        this.success(detail);
      })
      .catch(error => {
        console.warn('Sign in with Apple failed', error);
      });
  };

  // See https://developer.apple.com/documentation/signinwithapplejs/configuring_your_webpage_for_sign_in_with_apple
  success = detail => {
    const query = {
      code: detail.authorization.code,
    };

    const claims = jwtDecode(detail.authorization.id_token);
    const userId = claims.sub;
    if (detail.user) {
      saveAppleUser(userId, detail.user);
    }

    const user = getAppleUsers()[userId];
    if (user) {
      query.email = user.email;
      query.firstName = user.name.firstName;
      query.lastName = user.name.lastName;
    }

    this.props.history.push(`/connect/apple?${queryString.stringify(query)}`);
  };

  render() {
    return (
      <Button
        onClick={ () => { trackClick(this.props.text || 'Sign in with Apple'); this.click(); } }
        color="secondary"
        className="btn btn-md btn-normalcase"
        style={ {
          color: '#000',
          fontFamily: 'system-ui',
          fontWeight: 500,
        } }
      >
        <img className="btn-img" src={ appleIcon } style={ {
          marginBottom: -0.5,
          height: '1.04em',
        } } role="presentation" />
        <span className="btn-contents">{ this.props.text || 'Sign in with Apple' }</span>
      </Button>
    );
  }
}

export default AppleSigninButton;
