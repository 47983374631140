import { createRef, Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import login from 'data/auth/actions/login';
import Verify from 'login/containers/Verify';
import { AUTH_MODE } from 'shared/auth';
import getAuthMode from 'shared/auth/selectors/getAuthMode';
import isLoggedIn from 'shared/auth/selectors/isLoggedIn';
import { Divider } from 'shared/components';
import { AppleSigninButton } from 'shared/components/AppleSigninButton';
import GoogleSigninButton from 'shared/components/GoogleSigninButton';
import PasswordFields from 'shared/components/PasswordFields';
import Form from 'shared/form';
import { Input } from 'shared/form/inputs';
import isEmail from 'shared/form/validators/isEmail';
import useCountdown from 'shared/hooks/useCountdown';
import { trackClick } from 'shared/util/tracking/mercury';
import { posthogCapture } from 'shared/vendor/posthog';
import { clearNotices, errorNotice } from 'wiw/notices';
import { Button, PanelBody } from 'wiw/ui';
import FontIcon from 'wiw/ui/FontIcon';

import { debounce } from 'lodash';
import queryString from 'query-string';
import { Link, useHistory } from 'react-router-dom';

import 'login/styles/loginform.scss';

let savedFormValues = null;

function LoginPage(props) {

  const formRef = createRef();

  const dispatch = useDispatch();
  const history = useHistory();

  const [disabledDuration, setDisabledDuration] = useState(0);
  const [countdown, countdownString] = useCountdown(disabledDuration);
  const loggedIn = useSelector(isLoggedIn);
  const authMode = useSelector(getAuthMode);

  useEffect(() => {
    const query = queryString.parse(props.location.search);
    if (query.error) {
      switch (query.error) {
        case 'oauth2_not_registered':
          dispatch(errorNotice('There is already an account registered with this email. Please log in with email and password.', { duration: 5 }));
          break;
        case 'sso':
          dispatch(errorNotice('There was as error with single sign-on, please try again.', { duration: 5 }));
          break;
        default:
          dispatch(errorNotice('An error occurred, please try again.', { duration: 5 }));
          break;
      }
    }
  }, []);

  useEffect(() => {

    if (history.action !== 'POP') {
      savedFormValues = null;
    }

    if (loggedIn) {
      if (authMode === AUTH_MODE.MFA) {
        return history.push('/mfa/confirm');
      }
      return history.push('/accounts');
    }

    // copy ref internally for use in cleanup func
    const ref = formRef.current;
    return () => {
      savedFormValues = { email: ref?.getValues('email'), password: '' };
    };
  }, [formRef.current]);

  const clearLoginNotices = () => {
    debounce(() => {
      dispatch(clearNotices());
    }, 500)();
  };

  const handleSubmit = data => {
    return dispatch(login({ data }))
      .unwrap()
      .catch(error => {
        setDisabledDuration(error.response.headers['retry-after']);
      });
  };

  const onSignUpClick = () => {
    posthogCapture('Click to sign up', {
      'Sign up domain': 'login',
    });

    return history.push('/register');
  };

  if (props.verifyEmail) {
    return <Verify verifyEmail={ props.verifyEmail } />;
  }
  return (
    <div className="login">
      <PanelBody>
        <Form
          ref={ formRef }
          className="login-form no-gutters"
          onSubmit={ handleSubmit }
          onChange={ clearLoginNotices }
          defaultValues={ savedFormValues }
        >
          {({ isSubmitting }) => (
            <Fragment>
              <Input
                name="email"
                id="email"
                beforeAddon={ <FontIcon icon="email" /> }
                label="Email Address"
                autoCapitalize="none"
                validate={ {
                  isEmail: isEmail(),
                } }
                required
              />
              <PasswordFields baseValidation alwaysHideRequirements />
              <div className="row">
                <div className="col">
                  <Button
                    type="submit"
                    loading={ isSubmitting }
                    disabled={ countdown > 0 }
                    className="btn-login"
                    onClick={ event => trackClick(event.target.innerText) }
                  >
                    { countdown > 0 ? `Try again in ${ countdownString }` : 'Log in' }
                  </Button>
                </div>
              </div>


              <Divider>OR</Divider>
              <div className="two-buttons-container">
                <GoogleSigninButton onClick={ event => trackClick(event.target.innerText) } />
                <AppleSigninButton onClick={ event => trackClick(event.target.innerText) } history={ history } />
              </div>

              <Divider />

              <div className="two-buttons-container">
                <Button
                  to="/forgot"
                  color="secondary"
                  onClick={ event => trackClick(event.target.innerText) }>Forgot Password?</Button>
                <Button
                  color="secondary"
                  onClick={ event => { trackClick(event.target.innerText); onSignUpClick(); } }>Sign up</Button>
              </div>

            </Fragment>
          )}
        </Form>
      </PanelBody>
      <div className="row third-party no-gutters">
        <Link to="/connect" className="col" onClick={ event => trackClick(event.target.innerText) }>
          Third-Party Connect
        </Link>
      </div>
    </div>
  );
}

LoginPage.propTypes = {
  verifyEmail: PropTypes.string,
};

LoginPage.defaultProps = {
  verifyEmail: '',
};

export default LoginPage;
